<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedImageActions"
        :default-value="properties.action"
        step-label="image"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col v-if="properties.action === 'FROM_TEMPLATE'" cols="12">
      <text-templates-select
        :key="`${step.id}-textTemplate`"
        step-type="IMAGE"
        :properties="properties"
        step-label="image"
        :default-value="properties.fields.textTemplate"
        :readonly="!canEdit"
        :required="false"
        @change="handleChange('fields.textTemplate', $event)"
      />
      <locales-select
        :key="`${step.id}-locale`"
        :properties="properties"
        step-label="image"
        :readonly="!canEdit"
        :required="false"
        :default-value="properties.fields.locale"
        @change="handleChange('fields.locale', $event)"
      />
    </v-col>

    <v-col
      v-if="['CONVERT', 'RESCALE', 'DOWNLOAD', 'INFO'].includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-source`"
        v-model="properties.fields.source"
        outlined
        dense
        data-cy="image-source"
        :label="$lang.labels.source"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>

    <v-col
      v-if="['CONVERT', 'FROM_TEMPLATE'].includes(properties.action)"
      cols="12"
    >
      <v-select
        :key="`${step.id}-type`"
        v-model="properties.fields.type"
        :items="imageFormats"
        :label="$lang.labels.type"
        outlined
        dense
        required
        data-cy="image-format"
        :readonly="!canEdit"
        :class="{ 'required-asterisk': ['CONVERT', 'RESCALE'].includes(properties.action) }"
      />
    </v-col>

    <v-col
      v-if="['RESCALE', 'FROM_TEMPLATE'].includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-width`"
        v-model="properties.fields.width"
        outlined
        dense
        data-cy="image-width"
        :label="$lang.labels.width"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
    </v-col>

    <v-col
      v-if="['RESCALE', 'FROM_TEMPLATE'].includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-height`"
        v-model="properties.fields.height"
        outlined
        dense
        data-cy="image-height"
        :label="$lang.labels.height"
        :readonly="!canEdit"
        :class="{ 'required-asterisk': ['RESCALE'].includes(properties.action) }"
      />
    </v-col>

    <v-col
      v-if="['FROM_TEMPLATE'].includes(properties.action)"
      cols="12"
      class="pb-2"
    >
      <add-key-value
        :key="`${step.id}-variables`"
        type="variables"
        step-type="IMAGE"
        :can-edit="canEdit"
        :data="properties?.fields?.variables || {}"
        :single-step="step"
        @dataChanged="handleChange('fields.variables', $event)"
      ></add-key-value>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        data-cy="image-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect, TextTemplatesSelect, LocalesSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'
import { IMAGE_ACTIONS } from './constants'
import { getNestedPropertyForSetting, castValueForStep } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'

export default {
  name: 'ImageStep',
  components: {
    ActionSelect,
    TextTemplatesSelect,
    LocalesSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  data() {
    return {
      imageFormats: definitions.ConvertImageStepFields.properties.type.enum
    }
  },
  computed: {
    formattedImageActions() {
      return IMAGE_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.fields) this.properties.fields = {}
  },
  methods: {
    onHandleChange(key, value) {
      const castedValue = castValueForStep(
        key,
        value,
        {
          shouldBeString: ['fields.source', 'fields.textTemplate'], // Cast to String
          shouldBeMap: ['fields.variables'] // Cast to Map<String, String>
        }
      )

      // Call the mixin's handleChange with the casted value
      this.handleChange(key, castedValue)
    },
    onImageActionChange() {
      if (this.properties.action === 'RESCALE') {
        this.properties.fields = {
          width: '',
          height: ''
        }
      } else if (this.properties.action === 'CONVERT') {
        this.properties.fields = {
          type: ''
        }
      } else if (this.properties.action === 'FROM_TEMPLATE') {
        this.properties.fields = {
          type: '',
          width: '',
          height: '',
          locale: '',
          textTemplate: '',
          variables: {}
        }
      } else {
        this.properties.fields = {}
      }
    }
  }
}
</script>
